<template>
  <div>
    <content-template
      :menuName="`${
        inputType == 'Edit' ? $t('general.edit') : $t('general.new')
      } ${$t('APMA.actionPlanFile')}`"
      :backButton="true"
      @backToPage="cancelForm()"
    >
      <template #form>
        <form>
       
          <Input
            v-model="edited_item.title"
            :label="$t('general.title')"
            class="s-mb-20"
            :error="validation.title"
          />

          <Textarea
            v-model="edited_item.description"
            :label="$t('general.content')"
            class="s-mb-20"
            :error="validation.description"
          />

          <div class="s-pb-30 s-position-relative">
            <p class="s-text-primary s-weight-600">
              {{ $t("general.uploadFile") }}
            </p>
            <div v-if="inputType == 'Edit'"></div>
            <div class="s-pb-30 s-position-relative">
              <div v-if="!fileDoc.name" class="s-file-upload s-text-center">
                <div>
                  <img
                    :src="require('@/assets/icons/primary/cloud_upload.png')"
                  />
                  <p>{{ $t("general.dragAndDrop") }}</p>
                  <p>{{ $t("general.or") }}</p>
                  <Button>{{ $t("general.uploadFile") }}</Button>
                </div>
              </div>
              <input
                v-if="!fileDoc.name"
                id="document"
                ref="document"
                class="s-hide"
                type="file"
                @change="importData('document')"
                multiple
              />
              <div class="s-position-relative s-fullWidth s-center-flex">
                <div
                  class="
                    s-center-flex
                    s-mb-16
                    s-bg-green-40
                    s-plr-10
                    s-radius-10
                    s-fullWidth
                  "
                  v-if="fileDoc.name"
                >
                  <p class="s-text-primary">{{ fileDoc.name }}</p>
                  <img
                    :src="require('@/assets/icons/close-primary.svg')"
                    class="s-ml-auto"
                    @click="fileDoc = {}"
                    style="cursor: pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="s-mt-20 s-flex">
          <div class="s-ml-auto">
            <Button class="s-ml-auto" color="error" @click="cancelForm()">
              {{ $t("general.cancel") }}
            </Button>
            <Button
              class="s-ml-20"
              @click="onFinish()"
              :isLoading="isLoading"
              >{{ $t("general.submit") }}</Button
            >
          </div>
        </div>
      </template>
    </content-template>
  </div>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import form from "@/mixins/form";
import common from "@/mixins/common";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ContentTemplate },
  mixins: [form, common],
  props: ["inputType"],
  data: () => ({
    edited_item: {
      title: "",
      description: "",
    },

    validation: {
      title: false,
      description: false,
    },

    fileDoc: {},
    value: [],
    isLoading: false,
    //empty_field: false,
  }),
  computed: {
    ...mapGetters({
      expertise_dropdown: "expertise/getExpertise",
      actionplan_data: "actionplanfile/getActionPlan",
    }),
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    title() {
      return this.edited_item.title == "" ? true : false;
    },
  },
  mounted() {
    this.initData();
    this.getEditedData();
  },
  methods: {
    ...mapActions({
      actionplan: "actionplanfile/postActionPlanData",
      setExpertise: "expertise/setExpertiseDropdownData",
      setActionPlanSingleData: "actionplanfile/setActionPlanSingleData",
      actionplan_update: "actionplanfile/putActionPlanData",
    }),

    async initData() {
      this.setLoadingPage(true);
      await this.setExpertise();
      if (this.inputType == "Edit") {
        await this.setActionPlanSingleData(this.$route.params.id);
      }
      this.setLoadingPage(false);
    },

    async getEditedData() {
      if (this.inputType == "Edit") {
        await this.setActionPlanSingleData(this.$route.params.id);
        this.edited_item = Object.assign({}, this.actionplan_data);
        this.edited_item.title = this.actionplan_data.alt;
        this.fileDoc.name = this.actionplan_data.filename;
      }
    },

    async onFinish() {
      this.checkEmptyField(
        this.edited_item,
        this.validation,
        true,
        this.fileDoc.name,
        this.inputType
      );
      if (!this.empty_field) {
        this.isLoading = true;
        let formData = new FormData();
        formData.append("action_plan_id", this.$route.params.id);
        formData.append("title", this.edited_item.title);
        formData.append("description", this.edited_item.description);
        formData.append("file", this.fileDoc);

        if (this.inputType == "New") {
          await this.actionplan(formData);
          if (this.$store.getters["actionplanfile/getStatus"] == 1) {
            this.$router.push(
              this.$translate({
                name: "APMA File",
                params: { id: this.$route.params.id },
              })
            );
          }
        } else {
          formData.append("id", this.actionplan_data.id);
          await this.actionplan_update(formData);
          if (this.$store.getters["actionplanfile/getStatus"] == 1) {
            this.$router.push(
              this.$translate({
                name: "APMA File",
                params: { id: this.actionplan_data.assessment_id },
              })
            );
          } else {
            this.showSnackbar({
              type: "error",
              text: "general.addEditFailed",
            });
          }
        }
        this.isLoading = false;
      }
    },

    editItem() {
      if (this.item != null) {
        this.edited_item = Object.assign({}, this.item);
        this.edited_item.expertise_detail = this.item.expertise_detail.map(
          (el) => el.pivot.expertise_detail_id
        );
      }
    },
    importData(id) {
      const fileInput = this.$refs[id];
      const fileUpload = fileInput.files[0];
      const fsize = fileUpload.size;
      const file = Math.round(fsize / 1024);

      if (file <= 5000) this.fileDoc = fileUpload;
    },
    attachImage(id) {
      document.getElementById(id).click();
    },

    cancelForm() {
      if (this.inputType === "New")
        this.$router.push(
          this.$translate({
            name: "APMA File",
            params: { id: this.$route.params.id },
          })
        );
      else
        this.$router.push(
          this.$translate({
            name: "APMA File",
            params: { id: this.actionplan_data.assessment_id },
          })
        );
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
